





















import Vue from 'vue';
import Component from 'vue-class-component';
import { BeforeLeaveGuard } from '@/components/mixins/BeforeLeaveGuard';

import {
  DeviceRelation,
  DataApplication,
  DeviceModel,
} from '@/models/data/models';
import { Watch } from 'vue-property-decorator';
import ConfigSelector from './ConfigSelector.vue';
import { DEVICE_DEFAULT } from '@/models/device/defaults';
import { createDeviceRelations } from '@/apps/data/utils';
import BaseFormV2 from '@/components/common/forms/BaseFormV2.vue';

@Component({
  components: {
    ConfigSelector,
  },
  props: {
    id: {
      required: true,
    },
  },
  data() {
    return {
      DeviceRelation,
    };
  },
  mixins: [BeforeLeaveGuard],
})
export default class DataApplicationForm extends Vue {
  $refs: {
    baseForm: BaseFormV2;
  };

  parseIds(deviceIds: string): string[] {
    const lines = deviceIds.split('\n');
    const ids = [];
    lines.forEach(line => {
      const lineIds = line.split(',');
      lineIds.forEach(id => {
        ids.push(id.trim());
      });
    });
    ids.filter(id => {
      return id !== '';
    });
    return ids;
  }

  async customCreate(dataObject) {
    // loading indication and try/catch handled by BaseFormV2
    const applicationId = this.$store.getters['global/application'].id;
    if (!applicationId) {
      throw new Error('Application is required');
    }
    const role = this.$routerHandler.query('')['role'];
    if (!role) {
      throw new Error('Model is required');
    }
    const deviceIds = dataObject.device_ids || '';

    const clientAppId: string = this.$store.getters['global/selectedClientApp']
      .id;

    await createDeviceRelations(
      this.$apiv2,
      clientAppId,
      applicationId,
      this.parseIds(deviceIds),
      role,
    );
  }
}
